<template>
  <el-dialog
    title="设置提货单排序方式"
    :visible.sync="dialogVisible"
    width="60%"
    :before-close="handleClose"
    center=""
  >
    <div
      :class="
        origion && origion == PRINT_LOCATION_ENUM[1].value
          ? 'contentWrap'
          : 'contentWrap1'
      "
    >
      <div v-for="(aItem, aIndex) in dataList" :key="aIndex">
        <div class="nameWrap">{{ aItem.name }}:</div>
        <el-radio-group v-model="aItem.print_type">
          <el-radio :label="0"
            >系统排序：无需手动设置坑位号，按照下单件数由多到少设置</el-radio
          >
          <el-radio :label="1"
            >自定义坑位号，按照自己手动设置的展示坑位号
            <span
              style="color: red !important"
              @click="goToSet"
              v-if="showSetButton"
              >去设置</span
            >
          </el-radio>
        </el-radio-group>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="sureConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import {
  postTakeOrderPrintTypeList,
  postTakeOrderPrintTypeSave,
} from "@/api/customPit.js";
import { PRINT_LOCATION_ENUM } from "../utils/enum/index";
export default {
  name: "PrintSet",
  props: ["dialogVisible", "showSetButton"],
  data() {
    return {
      PRINT_LOCATION_ENUM,
      dataList: [],
      origion: "",
      batch_id: "",
    };
  },
  methods: {
    /**
     * 初始化数据
     */
    onInitData(type, data) {
      console.log(type, data, "data---");
      this.dataList = [];
      this.batch_id = data ? data.id : "";
      this.origion = type;
      if (type == this.PRINT_LOCATION_ENUM["1"].value) {
        // 顶部按钮
        this.postAjaxTakeOrderPrintTypeList();
      } else {
        let tmpData = {
          id: data.delivery.id,
          name: data.delivery.name,
          print_type: data.delivery.print_type,
        };
        this.dataList.push(tmpData);
      }
    },
    /**
     * 顶部批量操作 获取自提点列表
     */
    async postAjaxTakeOrderPrintTypeList() {
      try {
        const res = await postTakeOrderPrintTypeList();
        this.dataList = res.data || [];
      } catch (error) {
        console.log(error, "postAjaxTakeOrderPrintTypeList==");
      }
    },
    /**
     * 关闭弹窗
     */
    handleClose() {
      this.$emit("close");
    },
    sureConfirm() {
      this.postAJaxTakeOrderPrintTypeSave();
    },
    /**
     * 跳转自定义坑位号
     */
    goToSet() {
      this.$router.push({
        path: "/CustomPitLocation",
      });
    },
    /**
     * 保存配置
     */
    async postAJaxTakeOrderPrintTypeSave() {
      let params = { origion: this.origion };
      let delivery_list = [];
      this.dataList.map((dItem) => {
        delivery_list.push({
          id: dItem.id,
          print_type: dItem.print_type,
        });
      });
      params.delivery_list = delivery_list;
      if (this.origion == this.PRINT_LOCATION_ENUM["2"].value) {
        params.batch_id = this.batch_id;
      }
      try {
        const res = await postTakeOrderPrintTypeSave(params);
        this.$emit("close"); // 关闭弹窗
        if (this.origion == this.PRINT_LOCATION_ENUM["2"].value) {
          // 单个打印
          if (res.data.status == 1) {
            //  "您选择了手动设置坑位号,有用户没有坑位号,需先编辑坑位号"
            this.$confirm(res.data.msg, "", {
              confirmButtonText: "去设置",
              cancelButtonText: "取消",
              type: "none",
            })
              .then(() => {
                this.goToSet();
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消",
                });
              });
          } else {
            // 批量设置
            this.$message.success("设置成功");
            this.$emit("submitHandle");
            setTimeout(() => {
              this.$emit("setPrint"); //调打印
            }, 800);
          }
        } else {
          this.$message.success("设置成功");
          this.$emit("submitHandle");
        }
      } catch (error) {
        console.log(error, "postTakeOrderPrintTypeSave==");
      }
    },
  },
};
</script>
<style scoped lang="scss">
/deep/ .el-radio-group {
  display: inline-flex;
  margin-bottom: 20px !important;
}
.nameWrap {
  margin-bottom: 20px;
}
.contentWrap {
  height: 400px;
  overflow-y: auto;
}
.contentWrap1 {
  height: 200px;
  overflow-y: auto;
}
</style>
