/** 打印按钮 类型 1 顶部按钮保存  2 点击打印按钮保存 */
export const PRINT_LOCATION_ENUM = {
  1: {
    label: "顶部按钮保存",
    value: 1,
  },
  2: {
    label: "点击打印按钮保存",
    value: 2,
  },
};
